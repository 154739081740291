<template>
  <div class="home">
    <div class="title">
      支付中心
    </div>

    <div v-if="!if_show" class="main">
      <!-- 订单详情 -->
      <div class="order_main">
        <div class="name">
          <img src="@/assets/img/whxw.png" alt="">
          <div>{{ info.name }}</div>
        </div>

        <div class="order_title">订单</div>

        <div class="order_info">
          <div class="info_left">
            <img :src="info.image" alt="">
          </div>
          <div class="info_right">
            <div class="desc">{{ info.desc }}</div>
            <div class="price">￥{{ info.price }}</div>
          </div>
        </div>
      </div>

      <div v-html="hhh"></div>



      <!-- 支付类型 -->
      <div class="pay_type">
        <div class="item_title">
          付款方式
        </div>
        <div class="pay_main">
          <div class="pay_item" @click="chang_type('alipay')">
            <div class="pay_left">
              <img src="@/assets/img/zfb.png" alt="">

              <span style="">支付宝支付</span>
            </div>
            <div class="pay_right">
              <img v-if="pay_type == 'alipay'" src="@/assets/img/tab.png" alt="">
              <div v-else class="active"></div>
            </div>
          </div>

          <div class="pay_item" @click="chang_type('mihuawxpay')">
            <div class="pay_left">
              <img src="@/assets/img/wx.png" alt="">
              <span>微信支付</span>
            </div>
            <div class="pay_right">
              <img v-if="pay_type == 'mihuawxpay'" src="@/assets/img/tab.png" alt="">
              <div v-else class="active"></div>
            </div>
          </div>
        </div>


      </div>

      <!-- 订单备注 -->
      <div class="desc_order">
        <div class="item_title">
          订单备注
        </div>
        <div class="desc_main">
          <div class="desc_text">
            <textarea v-model="mem_note" placeholder="必须填写备注信息~"></textarea>
          </div>

        </div>


      </div>



    </div>

    <div v-show="hidshow" v-if="!if_show" class="pay" :style="ua ? 'height:80px' : 'height:46px'">
      <div class="pay_end">
        <div class="btn" @click="end_pay">确认支付</div>
        <div class="pay_price">
          待支付:<span>￥{{ info.price }}</span>
        </div>
      </div>
      <!-- <div style="height: 34px;"></div> -->
    </div>


    <div v-else-if="if_show == 2" style="text-align: center;height: 100vh;background: #F5F5F5; ">

    </div>

    <div v-else style="text-align: center ">
      <img src="@/assets/img/loading.png" style="width: 100%;" alt="">
      <div style="color:#999">{{ message }}</div>
    </div>




  </div>


</template>

<script>
// @ is an alias to /src

import axios from 'axios'
import Clipboard from 'clipboard';

export default {
  // name: 'HomeView',
  data() {
    return {
      pay_type: 'alipay',
      info: {},
      mem_note: '',
      hhh: null,
      if_show: 2,
      time: null,
      hidshow: true,
      docmHeight: window.innerHeight, //默认屏幕高度
      showHeight: window.innerHeight, //实时屏幕高度,
      message: '',

      ua: null,
      url: null,
    }
  },
  methods: {
    chang_type(type) {
      this.pay_type = type
    },

    //判断是否为鞍座ios
    finishActivity() {
      let ua = navigator.userAgent.toLowerCase();
      //android终端
      let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
      //ios终端
      let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //ios
        console.log(" 我是ios")
        this.ua = true
        this.isWeixinBrowser()
        try {
          window.webkit.messageHandlers.finishActivity.postMessage("");
        } catch (error) {
          console.log('WKWebView post message');
        }
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //android
        console.log("我是android")
        this.ua = false
        //这里是和安卓商量好的写法，调用安卓的finishActivity方法
        // android.finishActivity();

      }

    },



    // 判断是否为微信浏览器
    isWeixinBrowser() {
      if (~navigator.userAgent.indexOf('MicroMessenger')) {
        this.ua = true
        if (~navigator.userAgent.indexOf('wxwork')) {
          // 企业微信
        } else {
          return true
        }
      } else {
        // 非微信
        this.ua = false
      }



    },


    end_pay() {
      let that = this
      this.mem_note = this.mem_note.trim()  //去掉空格换行
      // this.mem_note = this.mem_note.replace(/\ +/g, "");
      if (this.mem_note == null || this.mem_note == '') {
        this.$alert("必须填写订单备注！");


        return
      }
      let data = {
        name: this.info.name,
        sid: this.info.id,
        mem_note: this.mem_note,
        amount: this.info.price,
        payway: this.pay_type,
        code: this.info.code
      }



      if (this.pay_type == 'alipay') {    //支付宝支付
        axios.post(' shopping/pay?format=json', data).then(res => {
          var str = res.data.data.btoken;
          if (str.search(/<form/) == -1) {
            this.hhh = res.data.data.token
          } else {
            this.hhh = res.data.data.btoken
          }
          this.time = setInterval(() => {

            this.$axios.get('shopping/verifyOrder?format=json&order_id=' + res.data.data.order_id).then(res => {
              console.log(res.data);


              if (res.data.data.status == 2) {
                clearInterval(this.time)
                this.$fire({
                  title: "支付成功",
                  type: "success",
                  timer: 0
                }).then(res => {
                  this.mem_note = '',

                    // this.$message({
                    //   message: '刷新成功',
                    //   type: 'success',
                    //   customClass: 'message'
                    // });
                    this.get_shop(this.$route.params.code)
                })

              }

            })
          }, 1000)

          setTimeout(() => {
            let arr = document.querySelectorAll('#alipaysubmit input')
            let url = 'https://mapi.alipay.com/gateway.do?'
            let params = {}

            arr.forEach(item => {
              params[item.name] = item.value
            })
            window.open(url + this.setQueryConfig(params), "_blank");
            // console.log({params});

          }, 100)
        })
      } else {          //微信支付
        if (this.isWeixinBrowser()) {



          this.$alert("微信浏览器暂不支付微信支付，点击复制链接前往系统自带浏览器支付", '', {
            confirmButtonText: '确定',
            showCancelButton: false
          }, {
            confirmButtonText: '复制链接',
            showCancelButton: false
          }).then(res => {

            this.$copyText(window.location.href).then(
              function (e) {
                console.log();
                that.$message({
                  message: '复制成功',
                  type: 'success',
                  customClass: 'message'
                });
              },
              function (e) {
                that.$message.error('复制失败');
              }
            );
          })
          return
        }
        axios.post(' shopping/pay?format=json', data).then(res => {
          // console.log(res.data.data);
          location.href = res.data.data.btoken
          console.log(res.data);
          this.time = setInterval(() => {

            this.$axios.get('shopping/verifyOrder?format=json&order_id=' + res.data.data.order_id).then(res => {
              console.log(res.data);


              if (res.data.data.status == 2) {
                clearInterval(this.time)
                this.$fire({
                  title: "支付成功",
                  type: "success",
                  timer: 0
                }).then(res => {

                  this.get_shop(this.$route.params.code)
                })

              }

            })
          }, 1000)
        })
      }






    },

    setQueryConfig(queryConfig) {
      var _str = "";
      for (var o in queryConfig) {
        if (queryConfig[o] != -1) {
          _str += o + "=" + queryConfig[o] + "&";
        }
      }
      var _str = _str.substring(0, _str.length - 1); //末尾是&
      return _str;
    }
    ,
    //获取商品
    get_shop(code) {
      this.$axios.get('shopping/index?format=json&code=' + code).then(res => {
        console.log(res);
        if (res.data.code == 200) {

          // this.$router.go(0)
          this.if_show = false
          this.info = res.data.data
          console.log(this.info);

        } else {

          this.message = res.data.msg
          this.if_show = true
        }

      }).catch(err => {
        this.message = '请求错误'
        this.if_show = true
      })
    }
  },

  mounted() {
    // window.onresize监听页面高度的变化
    window.addEventListener('resize', () => {
      this.showHeight = window.innerHeight
      console.log('this.showHeight:' + this.showHeight);
      console.log('this.docmHeight:' + this.docmHeight);
      if (this.docmHeight - this.showHeight > 50) {
        //Android系统: 软键盘弹出 隐藏footer
        this.hidshow = false
      } else {
        //Android系统: 软键盘收起 显示footer
        this.hidshow = true
      }
    });

  },



  created(e) {
    // document.body.addEventListener('touchmove', function (e) {

    //   e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)

    // }, { passive: false });
    // this.isWeixinBrowser()
    this.finishActivity()
    let code = this.$route.params.code
    console.log(this.$route);

    this.url = window.location.host
    console.log(this.url);

    this.$axios.get('shopping/index?format=json&code=' + code).then(res => {
      console.log(res);
      if (res.data.code == 200) {

        // this.$router.go(0)
        this.if_show = false
        this.info = res.data.data
        console.log(this.info);

      } else {

        this.message = res.data.msg
        this.if_show = true
      }

    }).catch(err => {
      this.message = '请求错误'
      this.if_show = true
    })
  }

}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  height: 100vh;


  .title {
    height: 44px;
    line-height: 44px;
    text-align: center;

    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
  }

  .main {
    padding: 0 16px;

    .order_main {

      background: #FFFFFF;
      border-radius: 6px;
      padding: 12px;

      .name {
        display: flex;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;

        border-bottom: 1px solid #F4F4F4;
        height: 32px;

        img {
          margin-right: 10px;
          width: 23px;
          height: 20px;
        }
      }

      .order_title {
        font-size: 14px;
        font-weight: bold;
        margin: 12px 0;
      }

      .order_info {

        display: flex;

        .info_left {
          width: 90px;
          height: 90px;
          border-radius: 6px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info_right {
          margin-left: 11px;
          flex: 1;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          position: relative;

          .desc {

            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .price {
            position: absolute;
            bottom: 0;
            right: 0;

          }
        }
      }
    }

    .pay_type {
      margin-top: 32px;


      .item_title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        margin-left: 12px;
        margin-bottom: 12px;
      }

      .pay_main {
        background: #FFFFFF;
        border-radius: 6px;

        .pay_item {
          height: 78px;
          padding: 0 12px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          .pay_left {
            display: flex;

            img {
              width: 24px;
              height: 24px;
            }

            span {
              color: #333333;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              margin-left: 10px;
            }


          }

          .pay_right {
            img {
              width: 20px;
              height: 20px;

              opacity: 1;
            }

            .active {
              width: 20px;
              height: 20px;
              background: #FFFFFF;
              border: 1px solid #DEDEDE;
              border-radius: 50%;
            }
          }
        }
      }





    }

    .desc_order {

      margin-top: 32px;

      border-radius: 6px;

      .item_title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        margin-left: 12px;
        margin-bottom: 12px;
      }

      .desc_main {
        // height: 94px;
        background: rgb(255, 255, 255);
        padding: 12px;

        .desc_text {
          background: #F5F5F5;
          border-radius: 4px;
          padding: 10px;

          textarea {

            background: #F5F5F5;
            outline: medium;
            border: none;
            width: 95%;
            height: 70px;
            font-size: 15px;
          }
        }

      }
    }

  }

  .pay {
    // height: 80px;
    background: #FFFFFF;
    position: fixed;

    bottom: 0;
    width: 100%;
    border-radius: 6px;

    .pay_end {
      .btn {
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        float: right;
        width: 128px;
        height: 46px;
        background: linear-gradient(90deg, #FF9E40 0%, #FF6A2E 100%);
      }

      .pay_price {
        color: #999;
        line-height: 46px;
        float: right;
        margin-right: 30px;

        span {
          color: #333333;
        }
      }
    }

  }

  .message {
    width: 20px;
  }




}
</style>
